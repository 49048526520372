<template>
  <div class="table-breakdown">
    <div class="row mt-2 mb-4">
      <div class="input-group col-lg-6 align-content-center">
        <span class="input-group-prepend">
          <b-button class="btn btn-sm btn-primary" @click="clearFilter">{{ $t('common.actions.clear') }}</b-button>
        </span>
        <b-form-input
          type="text"
          debounce="300"
          max="50"
          :placeholder="$t('enterForSearch')"
          v-model="table.filter"
          class="form-control form-control-sm"
        />
        <span class="input-group-append">
          <b-button class="btn btn-sm btn-primary" @click="fetchBuildings">
            <i class="fas fa-refresh mr-1"></i>
            <span>{{ $t('common.actions.refresh') }}</span>
          </b-button>
        </span>
      </div>
      <div class="col-lg-3 m-t-sm text-right" v-if="buildingsData && buildingsData.length">
        <row-count :size="table.size" :page="table.page" :total="buildingsData.length"></row-count>
      </div>
      <div class="col-lg-3 text-right" v-if="buildingsData && buildingsData.length && buildingsData.length > table.size">
        <b-pagination
          size="sm"
          class="m-t-xs"
          align="right"
          :total-rows="buildingsData.length"
          v-model="table.page"
          :per-page="table.size"
        ></b-pagination>
      </div>
    </div>
    <b-table
      responsive
      hover
      striped
      class="text-nowrap"
      thead-class="text-nowrap"
      :filter="table.filter"
      :fields="table.fields"
      :items="processedBuildings"
      :current-page="table.page"
      :per-page="table.size"
      :sort-by.sync="table.sortBy"
      :sort-desc.sync="table.sortDesc"
      @row-clicked="openBuildingDetails"
    >
    </b-table>

    <!-- Building Details Modal -->
    <b-modal v-model="showBuildingDetailsModal" size="xl" :title="selectedBuilding?.name" hide-footer dialog-class="modal-90w">
      <building-dashboard
        v-if="selectedBuilding"
        :building="selectedBuilding"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RowCount from '@/components/common/RowCount';
import BuildingDashboard from './BuildingDashboard.vue';
import axios from 'axios';
import { formatUnit } from "@/utils/converter";

export default {
  components: {
    RowCount,
    BuildingDashboard,
  },
  props: {
    comparisonMode: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
      default: "Building Energy Data",
    },
    selectedYears: {
      type: Array,
      default: () => [
        new Date().getFullYear(),
        new Date().getFullYear() - 1
      ]
    },
    selectedMonths: {
      type: Array,
      default: () => [
        new Date().getMonth(),
        new Date().getMonth() - 1 >= 0 ? new Date().getMonth() - 1 : 11
      ]
    },
    selectedMonthYear: {
      type: Number,
      default: () => new Date().getFullYear()
    }
  },
  data() {
    return {
      showBuildingDetailsModal: false,
      selectedBuilding: null,
      buildingUsage: {},
      fetchedBuildings: new Set(), // Track buildings already fetched
      table: {
        filter: "",
        fields: [
          { key: "name", label: this.$t("dashboard.table.headers.buildingName"), sortable: true },
          // { key: "energyUseIntensity", label: this.$t("dashboard.table.headers.energyUseIntensity"), sortable: true },
          { key: "electricity", label: this.$t("dashboard.table.headers.electricity"), sortable: true },
          { key: "electricityPerM2", label: this.$t("dashboard.table.headers.electricityPerM2"), sortable: true },
          { key: "districtHeating", label: this.$t("dashboard.table.headers.heating"), sortable: true },
          { key: "water", label: this.$t("dashboard.table.headers.water"), sortable: true },
          { key: "tailIndex", label: this.$t("dashboard.table.headers.tail"), sortable: true },
          { key: "energyTrend", label: this.$t("dashboard.table.headers.energyTrend"), sortable: true },
        ],
        page: 1,
        size: 10,
        sortBy: "name",
        sortDesc: false,
      },
    };
  },
  computed: {
    ...mapState({
      buildingsData: (state) => state.buildings.buildings,
      contextOrg: (state) => state.contextOrg,
      meteringPoints: (state) => state.devices.meters,
    }),
    processedBuildings() {
      return this.buildingsData && this.buildingsData.length
        ? this.buildingsData.map((building) => {
            const parameters = building.parameters || {};
            const floorArea = parameters.heatedFloorArea || 0;

            const electricity = this.getUsageForBuilding(building.id, 'ELECTRICITY');
            const districtHeating = this.getUsageForBuilding(building.id, 'DISTRICT_HEATING');
            const water = this.getUsageForBuilding(building.id, 'WATER');

            const formattedElectricity = formatUnit(electricity, 'kWh');
            const formattedDistrictHeating = formatUnit(districtHeating, 'kWh');
            const formattedWater = formatUnit(water, 'm3');

            const electricityPerM2 = floorArea > 0 ? (electricity / floorArea).toFixed(2) : null;
            const energyTrend = this.calculateEnergyTrend(building.id, 'ELECTRICITY');

            return {
              id: building.id,
              name: building.name,
              floorArea: floorArea,
              electricity: `${formattedElectricity.value} ${formattedElectricity.unit}`,
              electricityPerM2: electricityPerM2 ? `${electricityPerM2} kWh/m2`: "N/A",
              districtHeating: `${formattedDistrictHeating.value} ${formattedDistrictHeating.unit}`,
              water: `${formattedWater.value} ${formattedWater.unit}`,
              tailIndex: "N/A",
              energyTrend: energyTrend,
            };
          })
        : [];
    },
  },
  watch: {
    contextOrg: {
      handler(newOrg) {
        if (newOrg && newOrg.id) {
          this.fetchBuildings();
        }
      },
    },
    comparisonMode: {
      handler() {
        this.refreshBuildingData();
      },
    },
    selectedYears: {
      handler() {
        this.refreshBuildingData();
      }
    },
    selectedMonths: {
      handler() {
        this.refreshBuildingData();
      }
    },
    selectedMonthYear: {
      handler() {
        this.refreshBuildingData();
      }
    }
  },
  methods: {
    refreshBuildingData() {
      this.buildingUsage = {};
      this.fetchedBuildings.clear();
      if (this.contextOrg && this.contextOrg.id) {
        this.fetchBuildings();
      }
    },
    ...mapActions({
      fetchOrganisationBuildings: "buildings/fetchOrganisationBuildingsWithParameters",
    }),
    clearFilter() {
      this.table.filter = "";
    },
    calculateEnergyTrend(buildingId) {
      const currentUsage = this.buildingUsage[buildingId]?.ELECTRICITY || 0;
      const previousUsage = this.buildingUsage[buildingId]?.PREVIOUS_ELECTRICITY || 0;

      if (previousUsage === 0) return "N/A";

      return ((currentUsage - previousUsage) / previousUsage * 100).toFixed(1) + "%";
    },
    fetchBuildings() {
      if (this.contextOrg && this.contextOrg.id) {
        this.fetchOrganisationBuildings(this.contextOrg.id).then(() => {
          this.fetchAllBuildingsData();
        }).catch((error) => {
          console.error("Error fetching buildings:", error);
        });
      }
    },
    fetchAllBuildingsData() {
      if (!this.buildingsData?.length) return;

      this.buildingsData.forEach(building => {
        this.fetchBuildingData(building.id);
      });
    },
    fetchBuildingData(buildingId) {
      if (this.fetchedBuildings.has(buildingId)) return;
      this.fetchedBuildings.add(buildingId);

      const meteringPoints = this.meteringPoints.filter(point => point.building.id === buildingId);
      if (!meteringPoints.length) {
        console.warn(`No metering points found for building ID: ${buildingId}`);
        return;
      }

      // Calculate date ranges for both current and previous periods
      const { currentPeriod, previousPeriod } = this.calculateDateRanges();

      // Combine requests for both periods
      const requests = meteringPoints.flatMap(meteringPoint => [
        {
          request: this.createMeteringPointRequest(meteringPoint.id, currentPeriod.from, currentPeriod.to),
          meteringPointId: meteringPoint.id,
          isPrevious: false
        },
        {
          request: this.createMeteringPointRequest(meteringPoint.id, previousPeriod.from, previousPeriod.to),
          meteringPointId: meteringPoint.id,
          isPrevious: true
        }
      ]);

      Promise.all(requests.map(req => req.request))
        .then(responses => {
          const usage = {
            ELECTRICITY: 0,
            WATER: 0,
            DISTRICT_HEATING: 0,
            PREVIOUS_ELECTRICITY: 0
          };

          responses.forEach((response, index) => {
            const { meteringPointId, isPrevious } = requests[index];
            const meteringPoint = this.meteringPoints.find(point => point.id === meteringPointId);

            if (!meteringPoint) return;

            response.data.forEach(item => {
              const type = item.field;
              const value = parseFloat(item.value);

              if (type === "meter_energy_consumption") {
                if (meteringPoint.meteringPointSubtype === 'ELECTRICITY') {
                  if (isPrevious) {
                    usage.PREVIOUS_ELECTRICITY += value;
                  } else {
                    usage.ELECTRICITY += value;
                  }
                } else if (meteringPoint.meteringPointSubtype === 'DISTRICT_HEATING' && !isPrevious) {
                  usage.DISTRICT_HEATING += value;
                }
              } else if (type === "meter_volume" && meteringPoint.meteringPointSubtype === 'WATER' && !isPrevious) {
                usage.WATER += value;
              }
            });
          });

          this.$set(this.buildingUsage, buildingId, usage);
        })
        .catch(error => {
          console.error('Error fetching metering point data:', error);
        });
    },

    calculateDateRanges() {
      let currentStart, currentEnd, previousStart, previousEnd;

      if (this.comparisonMode === 'month') {
        currentStart = new Date(this.selectedMonthYear, this.selectedMonths[0], 1);
        currentEnd = new Date(this.selectedMonthYear, this.selectedMonths[0] + 1, 0);
        previousStart = new Date(this.selectedMonthYear, this.selectedMonths[1], 1);
        previousEnd = new Date(this.selectedMonthYear, this.selectedMonths[1] + 1, 0);
      } else if (this.comparisonMode === 'year') {
        currentStart = new Date(this.selectedYears[0], 0, 1);
        currentEnd = new Date(this.selectedYears[0], 11, 31);
        previousStart = new Date(this.selectedYears[1], 0, 1);
        previousEnd = new Date(this.selectedYears[1], 11, 31);
      }

      return {
        currentPeriod: {
          from: currentStart.toISOString(),
          to: currentEnd.toISOString()
        },
        previousPeriod: {
          from: previousStart.toISOString(),
          to: previousEnd.toISOString()
        }
      };
    },
    createMeteringPointRequest(meteringPointId, dateTimeFrom, dateTimeTo) {
      return axios.get(`/devices/${meteringPointId}/metering-point-values`, {
        params: {
          dateTimeFrom,
          dateTimeTo,
          aggregateWindowDuration: "1",
          aggregateWindowDurationUnit: "MONTHS"
        }
      });
    },

    getUsageForBuilding(buildingId, usageType) {
      return this.buildingUsage[buildingId] ? this.buildingUsage[buildingId][usageType] : 0;
    },
    openBuildingDetails(building) {
      if (building && building.id) {
        this.selectedBuilding = building;
        this.showBuildingDetailsModal = true;
      }
    },
  },
  mounted() {
    // Initialize building usage and fetch data when component is mounted
    this.buildingUsage = {};
    if (this.contextOrg && this.contextOrg.id) {
      this.fetchBuildings();
    }
  },
};
</script>

<style scoped>
.table-breakdown {
  margin-top: 20px;
}
.table-breakdown table {
  width: 100%;
  border-collapse: collapse;
}
.ibox-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top-right-controls {
  display: flex;
  margin-bottom: 15px;
  margin-left: auto;
}
.top-right-controls button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  width: 100px;
}
.top-right-controls button.active {
  background-color: #0056b3;
}
.input-group {
  display: flex;
  align-items: center;
}
::v-deep .modal-90w {
  max-width: 90vw !important;
  width: 90vw !important;
  margin: 1.75rem auto !important;
}
</style>
