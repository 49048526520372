<template>
  <div>
    <b-tabs v-model="currentPage" content-class="px-5 py-5 white-bg">
      <b-tab :title="$t('dashboard.energyDashboard.title')" lazy>
        <div class="col-lg-12" v-if="contextOrg">
          <div class="title-wrapper">
            <div class="top-right-controls">
              <div v-if="comparisonMode === 'year' || comparisonMode === 'monthByMonth'" class="selector-wrapper">
                <b-form-select
                  v-model="selectedYear2"
                  :options="availableYears"
                  class="year-select"
                />
                <span class="year-separator">vs</span>
                <b-form-select
                  v-model="selectedYear1"
                  :options="availableYears"
                  class="year-select"
                />
              </div>

              <div v-if="comparisonMode === 'month'" class="selector-wrapper">
                <div class="month-year-group">
                  <b-form-select
                    v-model="selectedMonthYear"
                    :options="availableYears"
                    class="year-select"
                  />
                  <b-form-select
                    v-model="selectedMonth2"
                    :options="monthOptions"
                    class="month-select"
                  />
                </div>
                <span class="separator">vs</span>
                <b-form-select
                  v-model="selectedMonth1"
                  :options="monthOptions"
                  class="month-select"
                />
              </div>
              
              <button class="btn btn-sm btn-primary" @click="setComparisonMode('monthByMonth')" :class="{ active: comparisonMode === 'monthByMonth' }">
                {{ $t('dashboard.options.monthByMonth') }}
              </button>
              <button class="btn btn-sm btn-primary" @click="setComparisonMode('month')" :class="{ active: comparisonMode === 'month' }">
                {{ $t('dashboard.options.month') }}
              </button>
              <button class="btn btn-sm btn-primary" @click="setComparisonMode('year')" :class="{ active: comparisonMode === 'year' }">
                {{ $t('dashboard.options.year') }}
              </button>
            </div>
          </div>

          <div class="dashboard-container" :class="{'month-by-month': comparisonMode === 'monthByMonth' }">
            <energy-dashboard
              :comparison-mode="comparisonMode"
              :selected-years="[selectedYear1, selectedYear2]"
              :selected-months="[selectedMonth1, selectedMonth2]"
              :selected-month-year="selectedMonthYear"
              :get-default-chart-options="getDefaultChartOptions"
            />
            <water-dashboard
              :comparison-mode="comparisonMode"
              :selected-years="[selectedYear1, selectedYear2]"
              :selected-months="[selectedMonth1, selectedMonth2]"
              :selected-month-year="selectedMonthYear"
              :get-default-chart-options="getDefaultChartOptions"
            />
            <heating-dashboard
              :comparison-mode="comparisonMode"
              :selected-years="[selectedYear1, selectedYear2]"
              :selected-months="[selectedMonth1, selectedMonth2]"
              :selected-month-year="selectedMonthYear"
              :get-default-chart-options="getDefaultChartOptions"
            />
          </div>
         

        </div>
      </b-tab>

      <!-- Table Breakdown Tab -->
      <b-tab :title="$t('dashboard.table.title')" lazy>
        <div class="col-lg-12">
          <div class="title-wrapper">
            <div class="top-right-controls">
              <div v-if="comparisonMode === 'year'" class="selector-wrapper">
                <b-form-select
                  v-model="selectedYear2"
                  :options="availableYears"
                  class="year-select"
                />
                <span class="year-separator">vs</span>
                <b-form-select
                  v-model="selectedYear1"
                  :options="availableYears"
                  class="year-select"
                />
              </div>

              <div v-if="comparisonMode === 'month'" class="selector-wrapper">
                <div class="month-year-group">
                  <b-form-select
                    v-model="selectedMonthYear"
                    :options="availableYears"
                    class="year-select"
                  />
                  <b-form-select
                    v-model="selectedMonth2"
                    :options="monthOptions"
                    class="month-select"
                  />
                </div>
                <span class="separator">vs</span>
                <b-form-select
                  v-model="selectedMonth1"
                  :options="monthOptions"
                  class="month-select"
                />
              </div>

              <button @click="setComparisonMode('month')" class="table-button" :class="{ active: comparisonMode === 'month' || comparisonMode === 'monthByMonth' }">
                {{ $t('dashboard.options.month') }}
              </button>
              <button @click="setComparisonMode('year')" :class="{ active: comparisonMode === 'year' }">
                {{ $t('dashboard.options.year') }}
              </button>
            </div>
          </div>

          <building-table
            :comparison-mode="comparisonMode"
            :selected-years="[selectedYear1, selectedYear2]"
            :selected-months="[selectedMonth1, selectedMonth2]"
            :selected-month-year="selectedMonthYear"
            :title="$t('dashboard.energyDashboard.title')"
            @set-comparison-mode="setComparisonMode"
          />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import EnergyDashboard from './EnergyDashboard.vue';
import WaterDashboard from './WaterDashboard.vue';
import HeatingDashboard from './HeatingDashboard.vue';
import BuildingTable from './BuildingTable.vue';

export default {
  name: 'AnalysisDashboard',
  
  components: {
    EnergyDashboard,
    WaterDashboard,
    HeatingDashboard,
    BuildingTable
  },

  data() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    
    return {
      comparisonMode: 'year',
      currentPage: 0,
      selectedYear1: currentYear,
      selectedYear2: currentYear - 1,
      selectedMonth1: currentMonth,
      selectedMonth2: currentMonth - 1 >= 0 ? currentMonth - 1 : 11,
      selectedMonthYear: currentYear,
      loading: false,
      previousRequestParams: {
        current: {},
        previous: {}
      }
    };
  },

  computed: {
    ...mapState({
      contextOrg: state => state.contextOrg
    }),

    availableYears() {
      const currentYear = new Date().getFullYear();
      return [
        currentYear,
        currentYear - 1,
        currentYear - 2
      ].map(year => ({
        value: year,
        text: year.toString()
      }));
    },

    monthOptions() {
      const monthNames = this.$t('dateRangePickerLabels.monthNames');
      return monthNames.map((name, index) => ({
        value: index,
        text: name
      }));
    },

    dateRanges() {
      let currentPeriod, previousPeriod;
      
      if (this.comparisonMode === 'year' || this.comparisonMode === 'monthByMonth') {
        currentPeriod = {
          from: new Date(this.selectedYear1, 0, 1),
          to: new Date(this.selectedYear1, 11, 31, 23, 59, 59)
        };
        previousPeriod = {
          from: new Date(this.selectedYear2, 0, 1),
          to: new Date(this.selectedYear2, 11, 31, 23, 59, 59)
        };
      } else if (this.comparisonMode === 'month') {
        currentPeriod = {
          from: new Date(this.selectedMonthYear, this.selectedMonth1, 1),
          to: new Date(this.selectedMonthYear, this.selectedMonth1 + 1, 0, 23, 59, 59)
        };
        previousPeriod = {
          from: new Date(this.selectedMonthYear, this.selectedMonth2, 1),
          to: new Date(this.selectedMonthYear, this.selectedMonth2 + 1, 0, 23, 59, 59)
        };
      }
      
      return { currentPeriod, previousPeriod };
    },

    selectionCriteria() {
      return {
        comparisonMode: this.comparisonMode,
        selectedYear1: this.selectedYear1,
        selectedYear2: this.selectedYear2,
        selectedMonth1: this.selectedMonth1,
        selectedMonth2: this.selectedMonth2,
        selectedMonthYear: this.selectedMonthYear
      };
    }
  },

  watch: {
    selectionCriteria: {
      handler() {
        this.fetchAllMeteringData();
      },
      deep: true
    },

    contextOrg: {
      immediate: true,
      handler(newOrg) {
        if (newOrg) {
          this.fetchAllMeteringData();
        }
      }
    }
  },

  methods: {
    ...mapActions({
      fetchMeteringData: 'dashboard/fetchMeteringData'
    }),

    setComparisonMode(mode) {
      this.comparisonMode = mode;
    },

    async fetchAllMeteringData() {
      if (!this.contextOrg || this.loading) return;

      this.loading = true;
      try {
        const { currentPeriod, previousPeriod } = this.dateRanges;
        const aggregateUnit = this.comparisonMode === ('year' || 'monthByMonth') ? 'MONTHS' : 'DAYS';
        const aggregateWindow = 1;

        const types = ['ELECTRICITY', 'WATER', 'DISTRICT_HEATING'];
        const requests = [];

        if (!this.previousRequestParams) {
          this.previousRequestParams = {
            current: {},
            previous: {}
          };
        }

        types.forEach(type => {
          if (!this.previousRequestParams.current[type]) {
            this.previousRequestParams.current[type] = null;
          }
          if (!this.previousRequestParams.previous[type]) {
            this.previousRequestParams.previous[type] = null;
          }

          const newCurrentParams = {
            organisationId: this.contextOrg.id,
            meteringPointSubtype: type,
            dateTimeFrom: currentPeriod.from,
            dateTimeTo: currentPeriod.to,
            aggregateWindowDuration: aggregateWindow,
            aggregateWindowDurationUnit: aggregateUnit
          };

          const newPreviousParams = {
            organisationId: this.contextOrg.id,
            meteringPointSubtype: type,
            dateTimeFrom: previousPeriod.from,
            dateTimeTo: previousPeriod.to,
            aggregateWindowDuration: aggregateWindow,
            aggregateWindowDurationUnit: aggregateUnit
          };

          if (!this.previousRequestParams.current[type] ||
            !this.areParamsEqual(this.previousRequestParams.current[type], newCurrentParams)) {
            requests.push(
              this.fetchMeteringData({
                ...newCurrentParams,
                period: 'current'
              })
            );
            this.previousRequestParams.current[type] = newCurrentParams;
          }

          if (!this.previousRequestParams.previous[type] ||
            !this.areParamsEqual(this.previousRequestParams.previous[type], newPreviousParams)) {
            requests.push(
              this.fetchMeteringData({
                ...newPreviousParams,
                period: 'previous'
              })
            );
            this.previousRequestParams.previous[type] = newPreviousParams;
          }
        });

        if (requests.length > 0) {
          await Promise.all(requests);
        }

      } catch (error) {
        console.error('Error fetching metering data:', error);
      } finally {
        this.loading = false;
      }
    },

    areParamsEqual(params1, params2) {
      if (!params1 || !params2) return false;
  
      return (
        params1.organisationId === params2.organisationId &&
        params1.meteringPointSubtype === params2.meteringPointSubtype &&
        params1.dateTimeFrom.getTime() === params2.dateTimeFrom.getTime() &&
        params1.dateTimeTo.getTime() === params2.dateTimeTo.getTime() &&
        params1.aggregateWindowDuration === params2.aggregateWindowDuration &&
        params1.aggregateWindowDurationUnit === params2.aggregateWindowDurationUnit
      );
    },

    getDefaultChartOptions() {
      return {
        chart: {
          type: 'column'
        },
        title: {
          text: ''
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          title: {
            text: undefined
          }
        },
        series: [],
        legend: { enabled: false },
        credits: {
          enabled: false
        }
      };
    }
  }
};
</script>

<style scoped>
.dashboard-wrapper {
  display: flex;
  flex-direction: column;
}

.dashboard-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
}

.month-by-month {
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
}

.grid-item {
  border: 1px solid #e7eaec;
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  position: relative;
}

.chart-box {
  border: 1px solid #e7eaec;
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  position: relative;
  height: 100%;
}

.chart-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #888;
  text-align: center;
  z-index: 3;
}

.greyed-out-chart {
  opacity: 0.3;
  position: relative;
}

.chart-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #000;
  text-align: center;
  z-index: 4;
}

.top-right-controls {
  display: flex;
  margin-bottom: 15px;
  margin-left: auto;
}

.top-right-controls button {
  padding: 4px 8px;
  margin-right: 8px;
  border: none;
  background-color: #8FC6CC;
  color: #fff;
  cursor: pointer;
  width: 100px;
  border-radius: 4px;
  height: 50px;
}

.top-right-controls button.active {
  background-color: #61AEB7;
}

.table-breakdown {
  margin-top: 20px;
}

.table-breakdown table {
  width: 100%;
  border-collapse: collapse;
}

.table-breakdown th, .table-breakdown td {
  border: 1px solid #e7eaec;
  padding: 10px;
  text-align: left;
}

.table-breakdown th {
  background-color: #f5f5f5;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.table-button{
  height: 40px;
}

.year-select {
  width: 100px;
  height: 40px;
  margin: 0 5px;
}

.year-separator {
  margin: 0 10px;
  color: #61AEB7;
  font-weight: bold;
}

.selector-wrapper {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.month-year-group {
  display: flex;
  align-items: center;
}

.month-select {
  width: 80px;
  height: 40px;
  margin: 0 5px;
}

.separator {
  margin: 0 10px;
  color: #61AEB7;
  font-weight: bold;
}

</style>