<template>
  <div class="subgrid">
    <div v-for="field in fields" :key="field" class="chart-box" :class="{ 'greyed-out-chart': !hasFieldData(field) }">
      <div class="chart-content">
        <div class="chart-area">
          <h6>{{ formatFieldName(field) }}</h6>
          <div class="chart-top" v-if="comparisonMode === 'monthByMonth'">
            <button @click="togglePercentageLabels" class="toggle-btn" :class="{ 'active': showPercentageLabels }">
              {{ showPercentageLabels ? $t('dashboard.buttons.hidePercentage') : $t('dashboard.buttons.showPercentage')
              }}
            </button>
          </div>
          <div class="chart-placeholder" v-if="!hasFieldData(field)" style="z-index: 2;">
            {{ $t('dashboard.noData') }}
          </div>
          <highcharts v-else :options="getChartOptions(field)" />
        </div>

        <!-- Stats Grid Below Chart -->
        <div v-if="comparisonMode !== 'monthByMonth'" class="stats-grid">
          <!-- Comparison Stats -->
          <div v-if="getFieldDiff(field)" class="stats-card">
            <div class="stats-header">{{ $t('dashboard.comparison.periodComparison') }}</div>
            <div :class="['stats-value', getFieldDiff(field).colorClass]">
              <span class="percentage">
                {{ getFieldDiff(field).percentage >= 0 ? '+' : '-' }}{{
                  Math.abs(getFieldDiff(field).percentage).toFixed(1) }}%
                <span class="unit-value">({{ getFieldDiff(field).value }} {{ getFieldDiff(field).unit }})</span>
              </span>
            </div>
            <div class="stats-subtitle">{{ $t('dashboard.comparison.comparedToPreviousPeriod') }}</div>
          </div>

          <!-- Peak Load/Emissions/Supply Stats -->
          <div v-if="getPeakInfo(field)" class="stats-card">
            <div class="stats-header">{{ getPeakLabel(field).title }}
              <span class="fa-solid fa-circle-question info-icon" v-b-tooltip.hover
                :title="$t(getPeakLabel(field).tooltip)"></span>
            </div>
            <div class="peak-grid">
              <div v-if="getPeakInfo(field).previousPeriod" class="peak-item">
                <div class="period-label">{{ getPeakLabel(field).previous }}</div>
                <div class="peak-value" style="color:#025286">
                  {{ getPeakInfo(field).previousPeriod.value }} {{ getPeakInfo(field).previousPeriod.unit }}
                </div>
                <div class="peak-date">{{ getPeakInfo(field).previousPeriod.time }}</div>
              </div>
              <div v-if="getPeakInfo(field).currentPeriod" class="peak-item">
                <div class="period-label">{{ getPeakLabel(field).current }}</div>
                <div class="peak-value" style="color:#61AEB7">
                  {{ getPeakInfo(field).currentPeriod.value }} {{ getPeakInfo(field).currentPeriod.unit }}
                </div>
                <div class="peak-date">{{ getPeakInfo(field).currentPeriod.time }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Monthly Stats -->
        <div v-if="comparisonMode === 'monthByMonth' && getMonthlyStats(field)" class="yearly-stats">
          <div class="stats-grid">
            <!-- Averages -->
            <div class="stats-card">
              <div class="stats-header">{{ $t('dashboard.averageConsumption.title') }}</div>
              <div class="peak-grid">
                <div class="peak-item">
                  <div class="period-label">{{ getPeakLabel(field).previous }}</div>
                  <div class="peak-value" style="color:#025286">
                    {{ getMonthlyStats(field).averages.previous.value }} {{
                      getMonthlyStats(field).averages.previous.unit }}
                  </div>
                </div>
                <div class="peak-item">
                  <div class="period-label">{{ getPeakLabel(field).current }}</div>
                  <div class="peak-value" style="color:#61AEB7">
                    {{ getMonthlyStats(field).averages.current.value }} {{ getMonthlyStats(field).averages.current.unit
                    }}
                  </div>
                  <div class="peak-date" :class="getMonthlyStats(field).totalDiff.colorClass">
                    {{ getMonthlyStats(field).totalDiff.percentage >= 0 ? '+' : '-' }}{{
                      Math.abs(getMonthlyStats(field).totalDiff.percentage).toFixed(1) }}%
                  </div>
                </div>
              </div>
            </div>

            <!-- Peak Values -->
            <div class="stats-card">
              <div class="stats-header">{{ getPeakLabel(field).title }} <span
                  class="fa-solid fa-circle-question info-icon" v-b-tooltip.hover
                  :title="$t(getPeakLabel(field).tooltip)"></span></div>
              <div class="peak-grid">
                <div class="peak-item">
                  <div class="period-label">{{ getPeakLabel(field).previous }}</div>
                  <div class="peak-value" style="color:#025286">
                    {{ getMonthlyStats(field).peaks.previous.value.value }} {{
                      getMonthlyStats(field).peaks.previous.value.unit }}
                  </div>
                  <div class="peak-date">
                    {{ getMonthlyStats(field).peaks.previous.month }} {{ getMonthlyStats(field).peaks.previous.year }}
                  </div>
                </div>
                <div class="peak-item">
                  <div class="period-label">{{ getPeakLabel(field).current }}</div>
                  <div class="peak-value" style="color:#61AEB7">
                    {{ getMonthlyStats(field).peaks.current.value.value }} {{
                    getMonthlyStats(field).peaks.current.value.unit }}
                  </div>
                  <div class="peak-date">
                    {{ getMonthlyStats(field).peaks.current.month }} {{ getMonthlyStats(field).peaks.current.year }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Highcharts from "highcharts";
import { formatUnit } from '@/utils/converter';

export default {
  name: "EnergyDashboard",
  props: {
    comparisonMode: {
      type: String,
      required: true,
    },
    getDefaultChartOptions: {
      type: Function,
      required: true,
    },
    selectedYears: {
      type: Array,
      default: () => [
        new Date().getFullYear(),
        new Date().getFullYear() - 1
      ]
    },
    selectedMonths: {
      type: Array,
      default: () => [
        new Date().getMonth(),
        new Date().getMonth() - 1 >= 0 ? new Date().getMonth() - 1 : 11
      ]
    },
    isBuildingDashboard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPercentageLabels: false
    };
  },
  computed: {
    ...mapGetters({
      getDataByType: "dashboard/getDataByType",
    }),

    electricityData() {
      if (this.isBuildingDashboard) {
        return this.$store.state.dashboard.building.electricityData;
      }
      return this.getDataByType("ELECTRICITY");
    },

    fields() {
      const specificOrder = ["meter_energy_consumption", "meter_co2_emission_total", "meter_supplied_energy"];

      const fieldList = [
        ...(this.electricityData?.current ? Object.keys(this.electricityData.current) : []),
        ...(this.electricityData?.previous ? Object.keys(this.electricityData.previous) : [])
      ];

      const uniqueFields = [...new Set(fieldList)];

      // Order fields based on the specific order
      const orderedFields = specificOrder.filter(field => uniqueFields.includes(field));

      // Add the remaining fields that are not in the specific order
      const remainingFields = uniqueFields.filter(field => !specificOrder.includes(field));

      return [...orderedFields, ...remainingFields];
    }
  },
  methods: {
    hasFieldData(field) {
      const currentFieldData = this.electricityData?.current?.[field] || [];
      const previousFieldData = this.electricityData?.previous?.[field] || [];
      return currentFieldData.length > 0 || previousFieldData.length > 0;
    },

    togglePercentageLabels() {
      this.showPercentageLabels = !this.showPercentageLabels;
      // Refresh chart options to update labels
      this.$forceUpdate();
    },

    getChartOptions(field) {
      const currentData = this.electricityData?.current?.[field] || [];
      const previousData = this.electricityData?.previous?.[field] || [];

      if (this.comparisonMode === 'monthByMonth') {
        return this.getMonthlyChartOptions(currentData, previousData, field);
      }

      if (this.comparisonMode === 'year') {
        // For year comparison, sum up all values
        let currentTotal = this.calculateTotal(currentData);
        let previousTotal = this.calculateTotal(previousData);
        let unit = this.getFieldUnit(field);

        let forceConversion = (currentTotal > 1000 || previousTotal > 1000);

        currentTotal = formatUnit(currentTotal, unit, forceConversion);
        previousTotal = formatUnit(previousTotal, unit, forceConversion);
        unit = currentTotal.unit;

        return {
          ...this.getDefaultChartOptions(),
          chart: {
            type: 'column'
          },
          title: {
            text: null
          },
          xAxis: {
            categories: [this.selectedYears[1].toString(), this.selectedYears[0].toString()],
            crosshair: true
          },
          yAxis: {
            title: {
              text: unit
            }
          },
          series: [{
            showInLegend: false,
            data: [
              {
                y: parseFloat(previousTotal.value),
                color: '#025286',
                unit: previousTotal.unit
              },
              {
                y: parseFloat(currentTotal.value),
                color: '#61AEB7',
                unit: currentTotal.unit
              }
            ]
          }],
          tooltip: {
            shared: true,
            useHTML: true,
            style: {
              fontSize: '0.85rem'
            },
            formatter: function() {
              return `<b>${this.x}</b><br/>${Highcharts.numberFormat(this.y, 2)} ${this.point.unit}`;
            }
          }
        };
      }

      if (this.comparisonMode === 'month') {
        // For month comparison, sum up all values
        const months = this.$t('dateRangePickerLabels.monthNames');

        let currentTotal = this.calculateTotal(currentData);
        let previousTotal = this.calculateTotal(previousData);
        let unit = this.getFieldUnit(field);

        let forceConversion = (currentTotal > 1000 || previousTotal > 1000);

        currentTotal = formatUnit(currentTotal, unit, forceConversion);
        previousTotal = formatUnit(previousTotal, unit, forceConversion);
        unit = currentTotal.unit;

        return {
          ...this.getDefaultChartOptions(),
          chart: {
            type: 'column'
          },
          title: {
            text: null
          },
          xAxis: {
            categories: [months[this.selectedMonths[1]], months[this.selectedMonths[0]]],
            crosshair: true
          },
          yAxis: {
            title: {
              text: unit
            }
          },
          series: [{
            showInLegend: false,
            data: [
              {
                y: parseFloat(previousTotal.value),
                color: '#025286',
                unit: previousTotal.unit
              },
              {
                y: parseFloat(currentTotal.value),
                color: '#61AEB7',
                unit: currentTotal.unit
              }
            ]
          }],
          tooltip: {
            shared: true,
            useHTML: true,
            style: {
              fontSize: '0.85rem'
            },
            formatter: function() {
              return `<b>${this.x}</b><br/>${Highcharts.numberFormat(this.y, 2)} ${this.point.unit}`;
            }
          }
        };
      }
      
      // Regular time series chart options
      const currentSeries = {
        name: this.$t("dashboard.currentPeriod"),
        data: currentData.map((item) => ({
          x: new Date(item.time).getTime(),
          y: parseFloat(item.value) || 0,
          unit: this.getFieldUnit(field)
        })),
        color: "#61AEB7",
      };

      const previousSeries = {
        name: this.$t("dashboard.previousPeriod"),
        data: previousData.map((item) => ({
          x: new Date(item.time).getTime(),
          y: parseFloat(item.value) || 0,
          unit: this.getFieldUnit(field)
        })),
        color: "#025286",
      };

      return {
        ...this.getDefaultChartOptions(),
        series: [previousSeries, currentSeries],
        xAxis: {
          type: "datetime",
          title: { text: this.$t("dashboard.time") },
        },
        yAxis: {
          title: { text: this.getFieldUnit(field) },
        },
        tooltip: {
          shared: true,
          useHTML: true,
          style: {
            fontSize: '0.85rem'
          },
          formatter: function() {
            let tooltip = `<b>${Highcharts.dateFormat('%Y-%m-%d', this.x)}</b><br/>`;
            this.points.forEach(point => {
              tooltip += `${point.series.name}: ${Highcharts.numberFormat(point.y, 2)} ${point.point.unit}<br/>`;
            });
            return tooltip;
          }
        }
      };
    },

    getMonthlyChartOptions(currentData, previousData, field) {
      const months = this.$t('dateRangePickerLabels.monthNames');
      const currentYear = this.selectedYears[0];
      const previousYear = this.selectedYears[1];

      // Group data by months
      const monthlyData = {
        current: Array(12).fill(0),
        previous: Array(12).fill(0)
      };

      currentData.forEach(item => {
        const month = new Date(item.time).getMonth();
        monthlyData.current[month] += parseFloat(item.value) || 0;
      });

      previousData.forEach(item => {
        const month = new Date(item.time).getMonth();
        monthlyData.previous[month] += parseFloat(item.value) || 0;
      });

      let unit = this.getFieldUnit(field);

      // Determine the correct unit by formatting the largest value to decide the scaling
      const largestCurrent = Math.max(...monthlyData.current);
      const largestPrevious = Math.max(...monthlyData.previous);
      const largestValue = Math.max(largestCurrent, largestPrevious);

      const forceConversion = largestValue > 1000;

      monthlyData.current = monthlyData.current.map(value => parseFloat(formatUnit(value, unit, forceConversion).value));
      monthlyData.previous = monthlyData.previous.map(value => parseFloat(formatUnit(value, unit, forceConversion).value));

      // Format the largest value to get the appropriate unit for all values
      unit = formatUnit(largestValue, unit).unit;

      // Calculate percentage differences
      const percentageDiffs = monthlyData.current.map((currentValue, index) => {
        const previousValue = monthlyData.previous[index];
        if (!previousValue) return null;
        return ((currentValue - previousValue) / previousValue) * 100;
      });

      return {
        ...this.getDefaultChartOptions(),
        chart: {
          type: 'column'
        },
        title: {
          text: null
        },
        xAxis: {
          categories: months,
          crosshair: true
        },
        yAxis: {
          title: {
            text: unit
          }
        },
        plotOptions: {
          column: {
            grouping: true,
            shadow: false,
            borderWidth: 0,
            dataLabels: {
              enabled: this.showPercentageLabels,
              formatter: function() {
                if (this.point.percentageDiff !== undefined && this.point.percentageDiff !== null) {
                  const sign = this.point.percentageDiff >= 0 ? '+' : '';
                  return `<span style="color: ${this.point.percentageDiff >= 0 ? '#DF5353' : '#55BF3B'}">${sign}${this.point.percentageDiff.toFixed(1)}%</span>`;
                }
                return '';
              },
              style: {
                fontSize: '11px',
                fontWeight: 'bold'
              },
              rotation: -45,
              crop: false,
              y: -6,
              x: 4,
              allowOverlap: true
            }
          }
        },
        legend: {
          enabled: true,
          align: 'center',
          verticalAlign: 'bottom',
          layout: 'horizontal',
          itemStyle: {
            fontSize: '12px'
          }
        },
        series: [
          {
            name: `${previousYear}`,
            data: monthlyData.previous.map(value => ({
              y: value,
              unit: unit
            })),
            color: '#025286',
            dataLabels: {
              enabled: false // no labels on previous period bars
            }
          },
          {
            name: `${currentYear}`,
            data: monthlyData.current.map((value, index) => ({
              y: value,
              unit: unit,
              percentageDiff: percentageDiffs[index]
            })),
            color: '#61AEB7'
          }
        ],
        tooltip: {
          shared: true,
          useHTML: true,
          style: {
            fontSize: '0.85rem',
          },
          formatter: function() {
            let tooltip = `<b>${this.x}</b><br/>`;
            
            this.points.forEach(point => {
              tooltip += `${point.series.name}: ${Highcharts.numberFormat(point.y, 2)} ${point.point.unit}<br/>`;
            });

            const current = this.points.find(p => p.series.name === `${currentYear}`)?.y;
            const previous = this.points.find(p => p.series.name === `${previousYear}`)?.y;

            if (current !== undefined && previous !== undefined && previous !== 0) {
              const diff = current - previous;
              const percentChange = (diff / previous) * 100;
              const sign = diff >= 0 ? '+' : '-';
              
              tooltip += `<span style="color: ${diff >= 0 ? '#DF5353' : '#55BF3B'}">` +
                `${sign}${Highcharts.numberFormat(Math.abs(diff), 2)} ${unit} ` +
                `(${sign}${Highcharts.numberFormat(Math.abs(percentChange), 1)}%)</span>`;
            }
            
            return tooltip;
          }
        }
      };
    },

    getPeakInfo(field) {
      const currentData = this.electricityData?.current?.[field] || [];
      const previousData = this.electricityData?.previous?.[field] || [];

      if (!currentData.length && !previousData.length) return null;

      const unit = this.getFieldUnit(field);
      
      const findPeak = (data) => {
        if (!data.length) return null;
        const peak = data.reduce((max, item) => {
          const value = parseFloat(item.value) || 0;
          return value > max.value ? { value, time: item.time } : max;
        }, { value: -Infinity, time: null });

        if (peak.value === -Infinity) return null;

        const formatted = formatUnit(peak.value, unit);
        return {
          value: formatted.value,
          unit: formatted.unit,
          time: this.formatPeakDate(peak.time)
        };
      };

      return {
        currentPeriod: findPeak(currentData),
        previousPeriod: findPeak(previousData)
      };
    },

    getMonthlyStats(field) {
      if (!this.hasFieldData(field)) return null;

      const currentData = this.electricityData?.current?.[field] || [];
      const previousData = this.electricityData?.previous?.[field] || [];
      const unit = this.getFieldUnit(field);

      // Group data by months
      const monthlyData = {
        current: Array(12).fill(0),
        previous: Array(12).fill(0)
      };

      currentData.forEach(item => {
        const month = new Date(item.time).getMonth();
        monthlyData.current[month] += parseFloat(item.value) || 0;
      });

      previousData.forEach(item => {
        const month = new Date(item.time).getMonth();
        monthlyData.previous[month] += parseFloat(item.value) || 0;
      });

      // Calculate averages
      const calculateAverage = (data) => {
        const validMonths = data.filter(value => value > 0).length;
        return validMonths ? data.reduce((sum, value) => sum + value, 0) / validMonths : 0;
      };

      const avgCurrent = calculateAverage(monthlyData.current);
      const avgPrevious = calculateAverage(monthlyData.previous);

      // Find peaks
      const months = this.$t('dateRangePickerLabels.monthNames');
      const findPeak = (data) => {
        const maxValue = Math.max(...data);
        const maxMonth = data.indexOf(maxValue);
        return {
          value: formatUnit(maxValue, unit),
          month: months[maxMonth],
          year: data === monthlyData.current ? this.selectedYears[0] : this.selectedYears[1]
        };
      };

      // Calculate total difference
      const totalDiffPercentage = avgPrevious ? ((avgCurrent - avgPrevious) / avgPrevious) * 100 : 0;
      const totalDiffValue = formatUnit(Math.abs(avgCurrent - avgPrevious), unit);

      return {
        averages: {
          current: formatUnit(avgCurrent, unit),
          previous: formatUnit(avgPrevious, unit)
        },
        peaks: {
          current: findPeak(monthlyData.current),
          previous: findPeak(monthlyData.previous)
        },
        totalDiff: {
          percentage: totalDiffPercentage,
          value: totalDiffValue,
          colorClass: this.getValueColorClass(field, totalDiffPercentage)
        }
      };
    },

    calculateTotal(data) {
      return data.reduce((sum, item) => sum + (parseFloat(item.value) || 0), 0);
    },

    getFieldUnit(field) {
      const unitMap = {
        meter_energy_consumption: 'kWh',
        meter_supplied_energy: 'kWh',
        meter_co2_emission_total: 'g',
      };
      return unitMap[field] || '';
    },

    formatPeakDate(date) {
      if (!date) return '';
      const formatOptions = this.comparisonMode === 'year' 
        ? { month: '2-digit', year: 'numeric' }
        : { day: '2-digit', month: '2-digit', year: 'numeric' };
      return new Date(date).toLocaleDateString('en-GB', formatOptions);
    },

    formatFieldName(field) {
      const fieldNameMap = {
        meter_energy_consumption: this.$t('dashboard.energyDashboard.energyConsumption'),
        meter_co2_emission_total: this.$t('dashboard.energyDashboard.co2EmissionTotal'),
        meter_supplied_energy: this.$t('dashboard.energyDashboard.energySupplied')
      };
      return fieldNameMap[field] || field;
    },

    getPeakLabel(field) {
      const labelMap = {
        meter_energy_consumption: {
          title: this.$t('dashboard.peakLoad.title'),
          previous: this.$t('dashboard.peakLoad.previous'),
          current: this.$t('dashboard.peakLoad.current'),
          tooltip: 'dashboard.peakLoad.tooltip'
        },
        meter_co2_emission_total: {
          title: this.$t('dashboard.peakEmissions.title'),
          previous: this.$t('dashboard.peakEmissions.previous'),
          current: this.$t('dashboard.peakEmissions.current'),
          tooltip: 'dashboard.peakEmissions.tooltip'
        },
        meter_supplied_energy: {
          title: this.$t('dashboard.peakSupply.title'),
          previous: this.$t('dashboard.peakSupply.previous'),
          current: this.$t('dashboard.peakSupply.current'),
          tooltip: 'dashboard.peakSupply.tooltip'
        }
      };
      return labelMap[field] || labelMap.meter_energy_consumption;
    },

    getValueColorClass(field, percentage) {
      // Invert logic for supplied energy
      if (field === 'meter_supplied_energy') {
        return percentage >= 0 ? 'decrease' : 'increase';
      }
      // Normal logic for other fields
      return percentage >= 0 ? 'increase' : 'decrease';
    },

    // Update the getFieldDiff method to include field-specific logic
    getFieldDiff(field) {
      if (!this.hasFieldData(field)) return null;

      const currentTotal = this.calculateTotal(this.electricityData?.current?.[field] || []);
      const previousTotal = this.calculateTotal(this.electricityData?.previous?.[field] || []);

      if (!previousTotal) return null;

      const diff = ((currentTotal - previousTotal) / previousTotal) * 100;
      const formattedValue = formatUnit(Math.abs(currentTotal - previousTotal), this.getFieldUnit(field));

      return {
        percentage: diff,
        value: formattedValue.value,
        unit: formattedValue.unit,
        colorClass: this.getValueColorClass(field, diff)
      };
    },
  }
};
</script>

<style scoped>

.chart-box {
  border: 1px solid #e7eaec;
  padding: 20px;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 20px;
}

.chart-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chart-area {
  width: 100%;
  position: relative;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding-top: 20px;
  border-top: 1px solid #e7eaec;
}

.stats-card {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
}

.stats-header {
  font-size: 0.85rem;
  font-weight: 600;
  color: #666;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.stats-value {
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 4px;
}

.percentage {
  font-size: 1.5rem;
  font-weight: bold;
}

.trend {
  font-size: 0.85rem;
}

.stats-subtitle {
  font-size: 0.85rem;
  color: #666;
}

.unit-value {
  font-size: 1.5rem;
  font-weight: bold;
}

.increase {
  color: #DF5353;
}

.decrease {
  color: #55BF3B;
}

.peak-grid {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 24px;
}

.peak-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.period-label {
  font-size: 0.75rem;
  color: #666;
}

.peak-value {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin: 4px 0;
}

.peak-date {
  font-size: 0.75rem;
  color: #888;
}

.chart-header-container {
  position: relative;
}

.chart-header-container h6 {
  margin: 0;
}

.toggle-btn {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-4px);
  margin-bottom: 16px;
  padding: 4px 12px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #e7eaec;
  background: white;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 1;
}

.toggle-btn:hover {
  background: #f8f9fa;
}

.toggle-btn.active {
  background: #61AEB7;
  color: white;
  border-color: #61AEB7;
}

.chart-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.25rem;
  color: #888;
  text-align: center;
  z-index: 3;
}

.greyed-out-chart {
  opacity: 0.3;
  position: relative;
}

.info-icon{
  margin-left: 0.25rem;
  color: #61AEB7;
  font-size: 1rem;
}

.subgrid {
  display: contents;
}

</style>